document.addEventListener('DOMContentLoaded', function () {
  var shareBlock = document.querySelector('.share-block');
  var contestPhotos = document.querySelector('.contest-photos');

  if (shareBlock && contestPhotos) {
    // Move the shareBlock after contestPhotos
    contestPhotos.parentNode.insertBefore(
      shareBlock,
      contestPhotos.nextSibling
    );
  }
});
