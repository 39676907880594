window.addEventListener('load', function () {
  const Swiper = window.Swiper;
  if (document.querySelector('.page-header-carousel')) {
    new Swiper('.page-header-carousel', {
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      navigation: {
        prevEl: '.page-header-carousel .swiper-button-prev',
        nextEl: '.page-header-carousel .swiper-button-next',
      },
      pagination: {
        el: '.page-header-carousel .swiper-pagination',
        clickable: true,
      },
    });
  }
});
