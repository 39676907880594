/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
import Swiper from 'swiper/bundle';
window.Swiper = Swiper;
import AOS from 'aos/dist/aos.js';
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/page-header-carousel');
require('./main/display-share');

import vtxDomReady from './common/helpers';
import intrinsicRatioVideos from './common/video-ratios';
vtxDomReady(() => {
  AOS.init();
  intrinsicRatioVideos.init(); // Retain aspect ratio of videos on window resize.
});
